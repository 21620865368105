<template>
  <div>
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="85px"
      >
        <el-form-item label="商品名称：" prop="name">
          <el-input
            v-model="tableFrom.name"
            placeholder="请输入商品名称"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品状态：" prop="status">
          <el-select
            v-model="tableFrom.status"
            class="input-with-select selWidth"
            clearable
          >
            <el-option label="已上架" :value="1"></el-option>
            <el-option label="未上架" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属品牌：" prop="brandId">
          <el-select
            v-model="tableFrom.brandId"
            clearable
            class="input-with-select selWidth"
          >
            <el-option
              v-for="item in bandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为E3:" prop="pushType">
          <el-select
            v-model="tableFrom.pushType"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch()"
            >搜索</el-button
          >
          <el-button size="small" @click="handleReset()">重置</el-button>
          <el-button size="small" @click="exportFunList()">导出</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex; align-items: center">
        <el-upload
          class="upload-demo"
          action=""
          style="margin-right: 10px"
          :on-success="handleAvatarSuccess"
          :limit="1"
          :file-list="fileList"
          :http-request="uploadRequest"
          :disabled="showUpLoad"
          :before-upload="beforeUpload"
        >
          <el-button size="small" type="primary">商品导入</el-button>
        </el-upload>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="down"
        >
          模版导出
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="handleCreate"
          >新增商品</el-button
        >
      </div>
    </div>

    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          style="margin-top: 10px"
        >
          <el-tab-pane
            v-for="i in tabsList"
            :key="i.value"
            :label="i.label"
            :name="i.value"
          ></el-tab-pane> </el-tabs
      ></template>
      <template slot="mainImg" slot-scope="{ row }">
        <el-image
          style="width: 36px; height: 36px"
          :src="row.mainImg ? row.mainImg : ''"
          :preview-src-list="[row.mainImg ? row.mainImg : '']"
        ></el-image>
      </template>
      <template slot="name" slot-scope="{ row }">
        <div>
          <span
            class="tags_name"
            :style="row.specType == 0 ? 'color: #ff8a4d;' : 'color: #4073FA;'"
            :class="'name' + row.spec_type"
            >{{ row.specType == 0 ? "[单规格]" : "[多规格]" }}</span
          >{{ row.name || "-" }}
        </div>
      </template>

      <template slot="categoryName" slot-scope="{ row }">
        <div v-if="row.firstCategoryName">
          {{ `${row.firstCategoryName}/${row.secondCategoryName}` }}
        </div>
      </template>
      <template slot="E3" slot-scope="{ row }">
        <div>{{ row.e3 ? "是" : "否" }}</div>
      </template>
      <template slot="logoImg" slot-scope="{ row }">
        <el-image
          style="width: 36px; height: 36px"
          :src="row.logoImg ? row.logoImg : moren"
          :preview-src-list="[row.logoImg ? row.logoImg : moren]"
        ></el-image>
      </template>
      <!-- <template slot="isRecommend" slot-scope="{ row }">
        <el-switch
          v-model="row.isRecommend"
          :inactive-value="2"
          :active-value="1"
          @change="handleRecommend($event, row.id)"
        ></el-switch>
      </template> -->
      <template slot="status" slot-scope="{ row }">
        <el-switch
          v-model="row.vendorStatus"
          :inactive-value="0"
          :active-value="1"
          @change="handleState($event, row.id, row)"
        ></el-switch>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-info"
          @click="handleEdit(row, 1)"
          >详情</el-button
        >
        <el-button
          type="text"
          icon="el-icon-view"
          size="small"
          @click="onView(row)"
          >预览</el-button
        >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row, 2)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除</el-button
        >
      </template>
    </avue-crud>


      <GoodsView v-if="dialogVisible" :goodsId="goodsId" @closeView="dialogVisible=false" />
  </div>
</template>

<script>
// import {list, updateRecommend, updateState, deleteNew} from "@/api/goods/goods";
import { exportExcel } from "@/assets/utils/exportExcel";
import {
  list,
  getBrandList,
  deleteNew,
  exportData,
  updateState,
} from "@/api/supplierGoods/goods";

import GoodsView from "@/components/goodsView/goodsView";
import { goodBrandTotalListAll } from "@/api/goods/goods";
import { toolsts } from "@/api";
import OSS from "ali-oss";
import { guid } from "@/utils/other.js";
import * as XLSX from "xlsx";
export default {
  name: "index",
  components: {
    GoodsView,
  },
  data() {
    return {
      goodsId:'',
      dialogVisible:false,
      tabsList: [
        {
          label: "销售中",
          value: "1",
        },
        {
          label: "待审核",
          value: "0",
        },
        {
          label: "审核不通过",
          value: "2",
        },
      ],
      activeName: "1",
      showUpLoad: false,
      cascaderValue: [],
      tableFrom: {
        brandId: "",
        name: "",
        supplierName: "",
        classifyOneId: "",
        classifyTwoId: "",
        classifyThreeId: "",
        status: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      statusOptions: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      tableData: [],
      showLoading: false,
      option: {
        title: "",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menuWidth: 300,
        editBtn: false,
        border: false,
        delBtn: false,
        column: [
          {
            label: "商品主图",
            prop: "mainImg",
          },
          {
            label: "品牌名称",
            prop: "brandName",
          },
          {
            label: "商品名称",
            prop: "name",
          },
          {
            label: "分类名称",
            prop: "categoryName",
          },
          {
            label: "分组名称",
            prop: "classifyNames",
          },
          {
            label: "是否为E3",
            prop: "E3",
          },

          // {
          //   label: "商品分类",
          //   prop: "classifyOneName",
          //   formatter: function(row, column, cellValue, index) {
          //     return `${row.classifyOneName}/${row.classifyTwoName}/${row.classifyThreeName}`;
          //   },
          // },
          // {
          //   label: "销售价格（元）",
          //   prop: "salePrice",
          // },
          {
            label: "库存",
            prop: "stock",
          },
          {
            label: "销量",
            prop: "actualSaleNum",
          },
          {
            label: "状态",
            prop: "status",
            formatter: function (row, column, cellValue, index) {
              return `${row.status === 1 ? "上架" : "下架"}`;
            },
          },
          {
            label: "排序值",
            prop: "sort",
          },
        ],
      },
      bandList: [],
      fileList: [],
    };
  },
  mounted() {
    this.getBrandList();
  },
  methods: {
    onView(row){
      console.log('123',row)
      this.goodsId = row.id
    this.dialogVisible = true
    },
    exportFunList() {
      exportExcel("/goods/web/goods/supplier/pageList/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "商品列表",
      });
    },
    handleClick(e) {
      this.onLoad();
    },
    down() {
      const xhr = new window.XMLHttpRequest();
      xhr.open(
        "GET",
        "./商品导入模版.xls",
        true
      );
      xhr.responseType = "blob";
      xhr.send();
      xhr.onload = () => {
        if (xhr.status === 200) {
          const url = window.URL.createObjectURL(xhr.response);
          const a = document.createElement("a");
          a.href = url;
          a.download = `商品导入模版.xls`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      };
    },
    beforeUpload(file) {
      this.fileList = [];
      this.readExcel(file);
    },
    readExcel(file) {
      if (
        !(
          ".xls,.xlsx".indexOf(
            file.name.substring(file.name.lastIndexOf("."))
          ) > -1
        )
      ) {
        this.$message.error("请上传xls或xlsx格式文件");
        return;
      }
      const f = file;
      const reader = new FileReader();
      reader.onload = async (e) => {
        const datas = e.target.result;
        const workbook = XLSX.read(datas, { type: "binary" }); //解析datas
        const first_worksheet = workbook.Sheets[workbook.SheetNames[0]]; //是工作簿中的工作表的第一个sheet
        const jsonArr = XLSX.utils.sheet_to_json(first_worksheet, {
          header: 1,
          defval: "",
        }); //将工作簿对象转换为JSON对象数组
        jsonArr.splice(0, 1);
        let dataFile = [];
        jsonArr.map((item, index) => {
          // 判断第一项是否为/ 如果是/ 那么循环索引 找到最后一条数据添加规格数据
          if (item[0] == "/") {
            for (let i = 0; i <= index; i++) {
              if (dataFile[index - i]) {
                dataFile[index - i].goodsSkuList.push({
                  image: String(item[17]),
                  specNames: String(item[18]),
                  outerSkuCode: String(item[19]),
                  costPrice: String(item[20]),
                  originalPrice: String(item[21]),
                  // salePrice: String(item[21]),
                  // discountRatio: String(item[22]),
                  // integralName: String(item[23]),
                  // needIntegral: String(item[24]),
                  stock: String(item[22]),
                  volume: String(item[23]),
                  weight: String(item[24]),
                });
                return;
              }
            }
          } else {
            dataFile.push({
              name: String(item[0]),
              brandName: String(item[1]),
              categoryName: String(item[2]),
              classifyNames: String(item[3]),
              sendDays: String(item[4]),
              mainImg: String(item[5]),
              logoImg: String(item[6]),
              video: String(item[7]),
              bannerImg: String(item[8]),
              introduction: String(item[9]),
              freight: String(item[10]),
              unit: String(item[11]),
              vendorStatus: String(item[12] == "上架" ? 1 : 0),
              shippingType: String(item[13] == "快递" ? 1 : 2),
              sendIntroduction: String(item[14]),
              outerGoodsCode: String(item[15]),
              sort: String(item[16]),
              goodsSkuList: [],
            });
          }
        });
        let dataList = dataFile.filter(function (item) {
          return (
            JSON.stringify(item).indexOf(
              '"sort":"","goodsSkuList":"","unit":"","shippingType":"","sendIntroduction":"","name":"",""brandName":"","categoryName":"","classifyNames":"","preSaleStartTime":"","preSaleEndTime":"","mainImg":"","video":"","bannerImg":"","introduction":"","freight":"",'
            ) === -1
          );
        });
        if (dataList.length <= 0) {
          this.$message.warning("不能导入空数据");
          return;
        }
        exportData({ list: dataFile }).then((res) => {
          if (res.data.list.length === 0) {
            this.onLoad();
          } else {
            this.$msgbox({
              title: "提示",
              message:
                ("p",
                null,
                [
                  ("span",
                  null,
                  `${
                    res.data.result == 3
                      ? "全部导入失败，问题数据请通过下载获取。"
                      : "部分数据已导入成功，问题数据请通过下载获取。"
                  }`),
                ]),
              showCancelButton: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = "执行中...";
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                } else {
                  done();
                }
              },
            }).then((action) => {
              this.exportList(res.data.list);
            });
          }
        });
      };
      reader.readAsBinaryString(f);
    },
    exportList(arr) {
      let tableData = [
        {
          title: "商品名称",
          dataIndex: "name",
        },
        {
          title: "商品品牌",
          dataIndex: "brandName",
        },
        {
          title: "商品分类（最后一级分类名称）",
          dataIndex: "categoryName",
        },
        {
          title:
            "商品分组（示例：波司登/波司登女装/冲锋衣,波司登/波司登女装/冲锋衣）多个分组用逗号分隔",
          dataIndex: "classifyNames",
        },
        {
          title: "多少天内发货（预售商品填写)",
          dataIndex: "sendDays",
        },
        {
          title: "商品主图",
          dataIndex: "mainImg",
        },
        {
          title: "商品logo",
          dataIndex: "logoImg",
        },
        {
          title: "商品视频",
          dataIndex: "video",
        },
        {
          title: "商品banner",
          dataIndex: "bannerImg",
        },
        {
          title: "商品简介",
          dataIndex: "introduction",
        },
        {
          title: "运费金额（包邮/填写金额）",
          dataIndex: "freight",
        },
        {
          title: "商品单位",
          dataIndex: "unit",
        },
        {
          title: "状态",
          dataIndex: "vendorStatus",
        },
        {
          title: "发货方式",
          dataIndex: "shippingType",
        },
        {
          title: "发货说明",
          dataIndex: "sendIntroduction",
        },

        {
          title: "外部商品编码",
          dataIndex: "outerGoodsCode",
        },
        {
          title: "排序值",
          dataIndex: "sort",
        },
        {
          title: "规格图片",
          dataIndex: "image",
        },
        {
          title: "规格名称（示例：颜色/黑色,尺码/M）多个规格之间用逗号分隔",
          dataIndex: "specNames",
        },
        {
          title: "外部规格编码",
          dataIndex: "outerSkuCode",
        },
        {
          title: "成本价",
          dataIndex: "costPrice",
        },
        {
          title: "吊牌价",
          dataIndex: "originalPrice",
        },
        // {
        //   title: "售价",
        //   dataIndex: "salePrice",
        // },
        // {
        //   title: "折扣率",
        //   dataIndex: "discountRatio",
        // },
        // {
        //   title: "积分类型名称",
        //   dataIndex: "integralName",
        // },
        // {
        //   title: "所需积分",
        //   dataIndex: "needIntegral",
        // },
        {
          title: "库存数",
          dataIndex: "stock",
        },
        {
          title: "体积",
          dataIndex: "volume",
        },
        {
          title: "重量",
          dataIndex: "weight",
        },
        {
          title: "表格错误提示",
          dataIndex: "msg",
        },
      ]; // 表格表头

      const header = tableData.map((item) => {
        return item.title;
      });
      // 筛选出符合key值的数据
      const tables = arr.map((val) => {
        return tableData.map((it) => {
          return val[it.dataIndex];
        });
      });
      const all = [];
      // 循环错误数据
      arr.map((val, index) => {
        // 循环规格数据
        let child = val.goodsSkuList.map((item) => {
          // 返回规格数据
          return tableData.map((it) => {
            return it.dataIndex == "name" ? "/" : item[it.dataIndex];
          });
        });
        all.push(tables[index], ...child);
      });

      let workSheet = XLSX.utils.aoa_to_sheet([header, ...all]);
      let bookNew = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(bookNew, workSheet, ""); // 工作簿名称
      let name = "商品导入模版" + this.timeFormat() + ".xlsx";
      XLSX.writeFile(bookNew, name); // 保存的文件名
    },
    //上传图片
    handleAvatarSuccess(res, file) {
      console.log(res, "resresres");
      if (res) {
      }
    },
    addZero(num) {
      return num < 10 ? "0" + num : num;
    },
    timeFormat() {
      let time = new Date();
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let date = time.getDate();
      let hours = time.getHours();
      let minutes = time.getMinutes();
      let seconds = time.getSeconds();
      return (
        year +
        "-" +
        this.addZero(month) +
        "-" +
        this.addZero(date) +
        " " +
        this.addZero(hours) +
        ":" +
        this.addZero(minutes) +
        ":" +
        this.addZero(seconds)
      );
    },
    //上传阿里云
    async uploadRequest(option) {
      const self = this;
      let file = option.file;
      let type = file.type;
      let size = file.size;
      let pos = file.name.lastIndexOf(".");
      let filename = file.name.substring(0, pos);
      let extendName = file.name.substring(pos + 1);
      // let date = new Date().getTime();
      // console.log(option, "optionoption");
      let fileNames = `${guid()}.${extendName}`; // 拼接文件名，保证唯一，这里使用时间戳+原文件名

      // await this.getOss();
      let client;
      toolsts()
        .then((res) => {
          if (res.code === 200) {
            // const {
            //   accessKeyId,
            //   accessKeySecret,
            //   stsToken,
            //   bucket,
            //   region,
            //   endpoint,
            // } = res.data;
            // const OSS = require("ali-oss");
            // client = new OSS({
            //   endpoint: endpoint,
            //   accessKeyId: accessKeyId,
            //   accessKeySecret: accessKeySecret,
            //   stsToken: stsToken,
            //   bucket: bucket,
            //   // secure: true,
            //   region: region,
            // });

            const {
              accessKeyId,
              accessKeySecret,
              stsToken,
              bucket,
              region,
              endpoint,
              accessKey,
              accessSecret,
            } = res.data;
            const OSS = require("ali-oss");
            client = new OSS({
              endpoint: endpoint,
              accessKeyId: accessKey,
              accessKeySecret: accessSecret,
              // stsToken: stsToken,
              bucket: bucket,
              // secure: true,
              region: region,
            });
            const options = {
              meta: { temp: "demo" },
              mime: "json",
              headers: { "Content-Type": "text/plain" },
            };
            client
              .put("headerimages/" + fileNames, option.file, options)
              .then((result) => {
                console.log(result, "resultresultresult");
                if (result.res.statusCode == 200) {
                  // 上传之后的文件地址
                  let uploadedUrl = result.res.requestUrls[0];
                  if (size >= 100 * 1024) {
                    uploadedUrl = result.res.requestUrls[0].split("?")[0];
                  } else {
                    uploadedUrl = result.res.requestUrls[0];
                  }

                  console.log(uploadedUrl, "uploadedUrluploadedUrluploadedUrl");
                  option.onSuccess(uploadedUrl); //触发el-upload组件的onsuccess方法
                  this.showUpLoad = false;
                }
              })
              .catch((err) => {
                this.$message.error("阿里云上传失败");
                this.showUpLoad = false;
              });

            // return client;
          } else {
            this.$message.error(res.msg);
            this.showUpLoad = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.showUpLoad = false;
        });
    },
    handleChange(e) {
      console.log(e, "eeeeee");
    },
    //商品品牌
    async getBrandList() {
      goodBrandTotalListAll(2).then((res) => {
        this.bandList = res.data;
      });
    },
    onLoad() {
      this.showLoading = true;
      list({
        pageNum: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.tableFrom,
        checkStatus: this.activeName,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    /* 新增商品 */
    handleCreate() {
      this.$router.push({
        path: "/supplierGoods/create",
        query: {
          type: 2,
        },
      });
    },
    /* 查询商品 */
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    /* 分类ID获取 */
    handleCascader(arr) {
      // console.log(arr)
      this.cascaderValue = arr;
      if (arr.length > 0) {
        this.searchData.classifyOneId = arr[0];
        this.searchData.classifyTwoId = arr[1] || "";
        this.searchData.classifyThreeId = arr[2] || "";
      } else {
        this.searchData.classifyOneId = "";
        this.searchData.classifyTwoId = "";
        this.searchData.classifyThreeId = "";
      }
    },
    /* 重置查询 */
    handleReset() {
      console.log("重置查询");
      this.tableFrom = {
        code: "",
        name: "",
        supplierName: "",
        classifyOneId: "",
        classifyTwoId: "",
        classifyThreeId: "",
        state: "",
      };
      this.page.currentPage = 1;
      this.cascaderValue = [];
      this.$refs.searchForm.resetFields();
      this.onLoad();
    },
    /* 编辑商品 */
    handleEdit(row, type) {
      if (row.vendorStatus === 1 && type != 1)
        return this.$message.error("请先下架商品在进行编辑操作~");
      this.$router.push({
        path: "/supplierGoods/create",
        query: {
          id: row.id,
          type,
        },
      });
    },
    /* 删除商品 */
    handleDelete(row) {
      console.log("删除商品", row);
      this.$confirm("是否删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    /* 推荐商品 */
    handleRecommend(e, id) {
      // console.log('推荐商品',e,id);
      updateRecommend({
        id,
        isRecommend: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning(res.msg);
        }
        this.onLoad();
      });
    },
    /* 上架商品 */
    handleState(e, id, row) {
      updateState({
        ids: [id],
        status: e,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
          } else {
            this.$message.warning(res.msg);
          }
          this.onLoad();
        })
        .catch(() => {
          this.tableData[
            this.tableData.findIndex((val) => val.id === id)
          ].vendorStatus = !e;
          console.log(this.tableData);
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
